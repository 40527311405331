import ReactFullpage from '@fullpage/react-fullpage';
import StyledContacts from './components/Contacts';
import StyledBlock from './components/Block';
import StyledLeader from './components/Leader';

const blocks = [
    {
        orientation: 'right',
        caption: 'Социологические исследования',
        items: [
            'Анкетные опросы',
            'Экспертное интервьюирование',
            'Фокус-группы',
            'Исследования в условиях негативного эмоционального фона',
            'Формирование конфликтологических карт',
        ],
    },
    {
        orientation: 'left',
        caption: 'Коммуникационные кампании',
        items: [
            'Адресный обход населения',
            'Сбор коллективных / индивидуальных обращений жителей',
            'Организация сбора подписей в рамках заочной части ОСС',
            'Формирование положительного эмоционального фона проекта',
        ],
    },
    {
        orientation: 'right',
        caption: 'Социальная конфликтология',
        items: [
            'Взаимодействие с лидерами общественного мнения',
            'Спикерство в полевых условиях',
            'Коммуникационное сопровождение строительства',
            'Организация информационных центров',
            'Конфликт-консалтинг',
        ],
    },
    {
        orientation: 'left',
        caption: 'Комплексное проведение ОСС',
        items: [
            'Формирование актуального реестра собственников',
            'Подготовка полного пакета документов ОСС',
            'Организация и проведение очно-заочного голосования',
            'Подготовка протокола общего собрания собственников',
        ],
    },
];



function App() {
    return (
        <ReactFullpage
            scrollOverflow={true}
            sectionsColor={['#e0dcd7']}
            anchors={['home', 'page1', 'page2', 'page3', 'page4', 'contacts']}
            verticalCentered={false}
            navigation={true}
            render={({ state, fullpageApi }) => {
                return (
                    <ReactFullpage.Wrapper>
                        <div className="section">
                            <StyledLeader />
                        </div>
                        {blocks.map((b) => {
                            return (
                                <div
                                    className="section services"
                                    key={b.caption}
                                >
                                    <StyledBlock
                                        caption={b.caption}
                                        items={b.items}
                                        orientation={b.orientation}
                                    />
                                </div>
                            );
                        })}
                        <div className="section">
                            <StyledContacts api={fullpageApi} />
                        </div>
                    </ReactFullpage.Wrapper>
                );
            }}
        />
    );
}

export default App;
