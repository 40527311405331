import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import backi from '../images/backi.jpg';
import call from '../images/call.svg';
import check from '../images/check.svg';
import email from '../images/email.svg';
import up from '../images/up.svg';
import { animated, useSpring } from 'react-spring';
import wa from '../images/whatsapp_logo-2x.png';

const Contacts = ({ className, api }) => {
    const [submitted, setSubmitted] = useState(false);

    const { handleSubmit, getFieldProps, errors, touched, isValid, dirty } = useFormik({
        initialValues: {
            email: '',
            phone: '',
            messsage: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required(),
            phone: Yup.string().required(),
            message: Yup.string().required(),
        }),
        onSubmit(values) {
            var payload = {
                email: values.email,
                phone: values.phone,
                request: values.message,
            };

            var data = new FormData();

            for (const name in payload) {
                data.append(name, payload[name]);
            }

            fetch('/mail/mail.php', {
                method: 'POST',
                body: data,
            });

            setSubmitted(true);
            setTimeout(() => {
                api.reBuild();
            }, 1000);
        },
    });

    return (
        <div className={className}>
            <div className="divider"></div>

            <div className="about">
                <h2>О компании</h2>
                <div className="leader">
                    <p>
                        Агентство ПОЛЕВИК.РФ предоставляет услуги в сфере прямых коммуникаций с населением, основываясь
                        на комплексной реализации проектов: от детального анализа проблематики и разработки необходимого
                        инструментария, до решения поставленной задачи и подготовки отчетной документации.{' '}
                    </p>
                    <p>
                        Нашей командой было проведено более 150 социологических исследований в разных регионах России,
                        успешно реализовано свыше 100 коммуникационных кампаний на территории Москвы и Московской
                        области.
                    </p>
                    <p>
                        Специалистами нашей компании было проведено свыше 100 общих собраний собственников
                        многоквартирных домов, разной повестки и уровня сложности.
                    </p>
                    <p>
                        Мы применяем методичный подход к процессу взаимодействия с населением, опираясь на многолетний
                        опыт в лице команды аналитиков, профессиональных конфликтологов и полевых специалистов разного
                        профиля.
                    </p>
                    <p>
                        <i>Будем рады сотрудничеству, коллектив ПОЛЕВИК.РФ</i>
                    </p>
                </div>
            </div>

            <div className="contacts">
                {!submitted && (
                    <div className="contact form">
                        <p>Обратная связь</p>
                        <Form onSubmit={handleSubmit} noValidate>
                            <Form.Group>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...getFieldProps('email')}
                                    isInvalid={errors.email && touched.email}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="phone"
                                    placeholder="Телефон"
                                    {...getFieldProps('phone')}
                                    isInvalid={errors.phone && touched.phone}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Ваш вопрос"
                                    rows={3}
                                    {...getFieldProps('message')}
                                    isInvalid={errors.message && touched.message}
                                />
                            </Form.Group>
                            <Button variant="danger" type="submit">
                                Отправить
                            </Button>
                        </Form>
                    </div>
                )}
                {submitted && <OkForm />}
                <div className="contact btn" onClick={() => window.open('https://wa.me/79099555225', '_blank')}>
                    <img src={wa} width="70px" alt="Whatsapp" />
                    <p>Whatsapp</p>
                    <a href="https://wa.me/79099555225" target="_blank" rel="noreferrer">
                        +7 909 955-52-25
                    </a>
                </div>
                <div className="contact btn" onClick={() => (window.location.href = 'mailto:info@polevik.org')}>
                    <img src={email} width="70px" alt="Электронная почта" />
                    <p>Почта</p>
                    <a href="mailto:info@polevik.org">info@polevik.org</a>
                </div>
            </div>
            <footer>
                <p>
                    &copy; 2019&mdash;{new Date().getFullYear()} <a href="//полевик.рф">полевик.рф</a>
                </p>
                <img src={up} width="20px" alt="Up" onClick={() => api.moveTo(1, 0)} />
            </footer>
        </div>
    );
};

const StyledContacts = styled(Contacts)`
    display: flex;
    flex-direction: column;
    height: 100%;

    form > div {
        margin-bottom: 0.5rem;
    }

    & > .divider {
        min-height: 1.5rem;
        color: black;
        background: url(${backi}) no-repeat center/cover;
    }
    & > .about {
        display: flex;
        padding: 2rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 20%);

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    & h2 {
        width: fit-content;
        min-width: 25%;
    }
    & > .contacts {
        flex-grow: 1;
        color: black;
        background: url(${backi}) no-repeat center/cover;
    }

    & > .contacts p,
    & > .footer p {
        color: white;
    }

    & > .contacts {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        place-items: center;
    }

    & .contact {
        flex-basis: 33%;
        text-align: center;
        margin: 2rem;
    }

    & .contact.btn {
        transition: ease 0.1s;
    }

    & .contact.btn:hover {
        transform: scale(1.05);
    }

    & .contact p,
    footer {
        color: #999;
    }

    & .contact a,
    footer a {
        color: #eee;
    }

    & .contact a:hover,
    footer a:hover {
        color: #fff;
        text-decoration: none;
    }

    & .contact p {
        margin: 0.3rem;
    }

    & .form-group {
        margin: 0.5rem 0;
    }

    //prettier-ignore
    & .contact input, textarea, textarea: focus {
        background-color: #212529;
        border-color: #3a3a3a;
        color: #bebebe;
    }

    & .contact form {
        text-align: left;
    }

    footer {
        background-color: rgb(33, 37, 41);
        background: linear-gradient(180deg, rgb(29 32 35) 0%, rgb(33, 37, 41) 30%);
        padding: 1rem;
        display: flex;
        place-content: space-between;
    }

    footer p {
        margin: 0;
    }

    footer img {
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        & .contacts {
            flex-flow: row wrap;
        }
        & .contact {
            flex-basis: 100%;
        }
    }
`;

const OkForm = () => {
    const props = useSpring({
        from: {
            opacity: 0,
            transform: 'scale(0.7)',
        },
        to: {
            opacity: 1,
            transform: 'scale(1)',
        },
    });

    return (
        <animated.div className="contact" style={props}>
            <img src={check} width="70px" alt="" />
            <p>
                Спасибо за обращение!
                <br />
                Мы свяжемся с вами в ближайшее время
            </p>
        </animated.div>
    );
};

export default StyledContacts;
