import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

const BlockHeader = styled.h1`
    display: block;
    text-align: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    margin: 0;
    padding: 1.2rem;
    opacity: 0.7;
    font-size: 1.8rem;
    color: white;

    @media screen and (max-height: 400px) and (orientation: landscape) {
        font-size: 1.6rem;
    }
`;

const ServicesList = styled.div`
    position: relative;
    width: fit-content;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 1rem 2.5rem;
    line-height: 1.5rem;
    color: white;
    width: fit-content;

    & > ul {
        padding: 0;
    }

    @media screen and (max-height: 400px) and (orientation: landscape) {
        padding: 0.7rem 2rem;
    }
`;

const Block = ({ caption, items, orientation, className }) => {
    const [inView, setInView] = useState(false);

    const translate = orientation === 'right' ? '-50%' : '50%';

    const props = useSpring({
        from: {
            opacity: 0,
            transform: `translateX(${translate})`,
            float: orientation,
            margin: '2%',
        },
        to: {
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateX(0)' : `translateX(${translate})`,
            float: orientation,
            margin: '2%',
        },
        delay: 250,
    });

    return (
        <div className={className}>
            <BlockHeader>{caption}</BlockHeader>
            <InView as="div" onChange={inView => setInView(inView)} triggerOnce>
                <animated.div style={props}>
                    <ServicesList orientation={orientation}>
                        <ul>
                            {items.map(m => {
                                return <li key={m}>{m}</li>;
                            })}
                        </ul>
                    </ServicesList>
                </animated.div>
            </InView>
        </div>
    );
};

const StyledBlock = styled(Block)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    @media screen and (max-height: 400px) and (orientation: landscape) {
        li {
            font-size: 0.8rem;
            line-height: 1.4;
        }
    }

    ul {
        margin-bottom: 0;
    }
`;

export default StyledBlock;
