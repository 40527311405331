import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../images/logobw.svg';
import moscow from '../images/moscow2.jpg';
import wa from '../images/whatsapp_logo-2x.png';
import arrow from '../images/arrow.svg';

const Logo = ({ className }) => {
    return (
        <div className={className}>
            <div className="moscow"></div>
            <div className="logo-wrapper">
                <img src={logo} alt="ПОЛЕВИК.РФ" />
                <div className="logo-text">
                    <h1>ПОЛЕВИК.РФ</h1>
                    <span>Агентство прямых коммуникаций</span>
                </div>
            </div>
        </div>
    );
};

const StyledLogo = styled(Logo)`
    margin: 1rem 1rem 1rem 1rem;
    display: flex;

    & img {
        width: 150px;
        margin-right: 2rem;
        margin-bottom: 2rem;
        display: block;
    }

    & .logo-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    & .logo-wrapper {
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    & h1 {
        font-family: 'latoHeavyCyr';
        font-size: 3rem;
        margin: 0;
    }

    & span {
        margin-top: -0.5rem;
    }

    & .moscow {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-image: url(${moscow});
        background-position: 0px 0px;
        animation: slide 90s linear infinite alternate;
        background-size: cover;
        width: 2400px;
        height: 100%;
    }

    @media only screen and (max-width: 768px) {
        & .moscow {
            animation: slide 300s linear infinite alternate;
        }

        & .logo-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & img {
            margin-right: 0;
        }
    }

    @keyframes slide {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(calc(-2400px + 100vw), 0, 0);
        }
    }
`;

const NavBar = () => {
    return (
      <Navbar collapseOnSelect expand bg="dark" variant="dark">
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <NavDropdown title="Виды деятельности">
              <NavDropdown.Item href="#page1">
                Социологические исследования
              </NavDropdown.Item>
              <NavDropdown.Item href="#page2">
                Коммуникационные кампании
              </NavDropdown.Item>
              <NavDropdown.Item href="#page3">
                Социальная конфликтология
              </NavDropdown.Item>
              <NavDropdown.Item href="#page4">
                Комплексное проведение ОСС
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="nav-right">
            <NavDropdown title="Контакты">
              <NavDropdown.Item href="https://wa.me/79099555225" target="_blank">
                <img
                  src={wa}
                  height={20}
                  style={{
                    margin: "0 4px 4px 0",
                  }}
                  alt=""
                />
                Whatsapp: +7 909 955-52-25
              </NavDropdown.Item>
              <NavDropdown.Item href="mailto:info@polevik.org">
                E-mail: info@polevik.org
              </NavDropdown.Item>
              <NavDropdown.Item href="#contacts">
                Обратная связь
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
};

const Leader = ({ className }) => {
    return (
        <div className={className}>
            <NavBar />
            <div className="warning">
                <p className="heading">Внимание!</p>
                <p>
                    Стали поступать сообщения, что от нашего имени ведется телефонный опрос жителей. На данный момент
                    поступают жалобы от жителей Санкт-Петербурга. Хотим сообщить, что компания ПОЛЕВИК.РФ никогда не
                    организовывала и не проводила телефонных опросов! При опросе наше название используют МОШЕННИКИ!
                </p>
                <p>
                    Просим присылать на почту входящие номера, с которых был произведен звонок, а также вопросы, которые
                    Вам задавали. Таким образом, Вы сможете помочь нам разобраться в данной ситуации и решить проблему.
                    Спасибо за понимание и содействие! С уважением, команда ПОЛЕВИК.РФ.
                </p>
            </div>
            <div className="main">
                <StyledLogo />
                <StyledArrow />
            </div>
        </div>
    );
};

const StyledLeader = styled(Leader)`
    height: 94%;

    & > .main {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > .warning {
        z-index: 1;
        position: absolute;
        background-color: #c60202;
        padding: 1rem 2rem;
    }

    & > .warning p {
        color: #fff;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }

    & > .warning p.heading {
        text-align: center;
        font-size: 1rem;
    }

    @media screen and (max-height: 400px) and (orientation: landscape) {
        a {
            font-size: 0.8rem;
            line-height: 1.3;
        }

        nav {
            padding: 0.2rem 0.5rem;
        }
    }

    @media screen and (min-height: 400px) {
        & > .warning {
            padding: .5rem;
        }

        & > .warning p {
            font-size: 0.6rem;
            margin-bottom: 0.3rem;
        }

        & > .warning p.heading {
            text-align: center;
            font-size: 0.8rem;
        }
    }

    nav {
        padding: 0 1rem;
        z-index: 2;
    }

    .navbar,
    .navbar-nav,
    .navbar-collapse,
    .bg-dark {
        background-color: #161616 !important;
    }

    .navbar-nav.nav-right {
        margin-left: auto;
    }
`;

export default StyledLeader;

const Arrow = ({ className }) => {
    return <div className={className} onClick={e => window.location.hash = '#page1'}></div>;
};

const StyledArrow = styled(Arrow)`
    background-image: url(${arrow});
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    border: none;
    cursor: pointer;
    height: 60px;
    width: 80px;
    margin: 0px 0 0 -40px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 100;
    animation: move_down 2s ease-in-out infinite;
    animation-delay: 1s;
    opacity: 0;

    @keyframes move_down {
        0% {
            transform: translate(0, -20px);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translate(0, 20px);
            opacity: 0;
        }
    }
`;
